@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;1,100&family=Playfair+Display&display=swap");
.navbarMobile{
  position: sticky;
  top: -1000px;
  height: 100vh;
  overflow: hidden;
}
.menu{
  display: block;
  width: 45px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.navbar {
  background-color: #754747;
  color: rgb(0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  transition: top 0.6s; 
}
.hidden {
  top: -60px;
}


.navbar-link {
  text-align: center;
  color: rgb(255, 255, 255);
  text-decoration: none;
  width: 150px;
  letter-spacing: 3px;
  font-size: 1rem;
  font-weight: 100;
  transition: 0.2s; 
  &:hover{
    transform: translateY(-2px);
    color: rgb(255, 255, 255);
  }
  @media only screen and (max-width: 960px) {
    font-size: 1.5rem;
    padding-bottom: 30px;
    text-align: left;
  }
  img{
    width: 100px;
  }
}
.navbar-logo {
  height: 40px;
  transition:  0.6s; 
  &:hover{
    transform: translateY(-2px);
    color: rgb(255, 255, 255);
  }
  img {
    height: 40px;
    @media only screen and (max-width: 960px) {
      height: 100px;
    }
  }

}


/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #754747;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #754747;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}