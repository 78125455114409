// @import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
.contact {
  min-height: 100vh;
  position: relative;
  background-color: #ffffff;

  &__ImgWrapper {
    position: relative;
    width: 50%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__image {
    transform: translateX(50px);
    max-width: 50%;
    display: block;
    box-shadow: 0 6px 20px rgba(43, 50, 63, 0.6);
    -webkit-filter: drop-shadow(0 6px 20px rgba(43, 50, 63, 0.6));
    filter: drop-shadow(0 6px 20px rgba(43, 50, 63, 0.6));
  }
  &__inlineStyle {
    top: 50%;
    left: 70%;
    position: absolute;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 25%;
    min-width: 370px;
    min-height: 600px;
    @media screen and (max-width: 960px) {
      left: 50%;
      width: 50%;
    }
    ::placeholder {
      color: #d1d1d1;
      opacity: 1; /* Firefox */
    }
  }
  &__title {
    color: #234750;
    margin-top: 20px;
    font-size: 2.5rem;
    text-shadow: 1px 1px black;
    font-weight: 400;
  }

  &__description {
    color: #234750;
    // text-shadow: 1px 1px black;
    padding: 20px;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.3;
  }

  &__textarea {
    font-size: 0.9rem;
    text-align: center;
    width: 80%;
    display: block;
    margin: 20px auto;
    padding: 10px;
    background-color: transparent;
    border: none;
    outline: none;
    resize: none;
    color: #234750;
  }

  &__subBtn {
    background-color: white;
    border: none;
    width: 80%;
    padding: 20px;
    cursor: pointer;
    transition: transform 0.2s;
    color: #fff;
    text-shadow: 1px 1px black;
    font-size: 1.1rem;
    font-weight: 400;
    background-color: #234750;
    &:hover {
      background-color: #0b191d;
    }
  }

  &__errors {
    color: rgb(255, 255, 255);
    text-decoration: underline;
    font-size: 1.1rem;
    font-weight: 700;
  }
  &__iconWrapper {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 35px;
    background-color: #234750;
  }
  &__icon {
    color: #754747;
    font-size: 5rem;
    text-decoration: none;
    padding: 20px;
    &:hover {
      transform: scale(1.2);
    }
  }
  &__input {
    font-size: 0.9rem;
    text-align: center;
    width: 80%;
    display: block;
    margin: 20px auto;
    padding: 10px;
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid rgb(204, 204, 204);
    color: #234750;
  }
}

.fa-envelope {
  display: block;
  padding: 10px;
  @media screen and (max-width: 960px) {
    padding: 0;
  }
}
.fa-phone-alt {
  display: block;
  padding: 10px;
  @media screen and (max-width: 960px) {
    padding: 0;
  }
}
.fa-facebook-f {
  display: block;
  padding: 10px;
  @media screen and (max-width: 960px) {
    padding: 0;
  }
}

.icon {
  width: 20px;
}
