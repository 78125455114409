.main__img {
    height: 100vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    @media screen and (max-width: 960px) {
        height: 300px;
        background-attachment: local;
      }
}
.main-text {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    font-size: 1.8rem;
    font-weight: 300;
    color: white;
    letter-spacing: 4px;
    line-height: 1.2;
    background-color: #754747de;
    padding: 20px;
    white-space: pre-wrap;

    @media screen and (max-width: 960px) {
        font-size: 1.3rem;
        position: initial;
        transform: translate(0, 0);
      }
}