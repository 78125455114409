
  .faqs {
    width: 100%;
    margin: 0 auto;
    padding: 15px;
  }

.faq {
    margin: 15px;
    padding: 15px;

    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

.question {
    color: rgb(0, 0, 0);
    position: relative;
    font-size: 27px;
    transition: all 0.4s;    
    @media screen and (max-width: 960px) {
      font-size: 15px;
    }
  }

  .answer {
    color: rgb(0, 0, 0);
    opacity: 0;
    max-height: 0;
    font-size: 15px;
    letter-spacing: 2px;
    line-height: 1.7;
    overflow-y: hidden;
    transition: all 0.4s ease-out;
    @media screen and (max-width: 960px) {
      font-size: 0.7rem;
      letter-spacing: 0.5;
    }
  }

  .open  {
    margin-bottom: 15px;
  }

//   .faq .question::after {
//     transform: translateY(-50%) rotate(180deg);
//   }
  .open .answer {
    max-height: 1000px;
    opacity: 1;
  }
// }


.arrow{
  width: 20px;
  margin-left: 20px;
transform: translateY(2px);
  @media screen and (max-width: 960px) {
    width: 10px;
    margin-left: 10px;
    transform: translateY(1px);
  }
}