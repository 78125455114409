.team__monika {
  display: flex;
  padding: 0 100px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    padding: 0 10px;
  }
}

.team__monika--img {
  margin-top: auto;
  margin-bottom: auto;
  width: 400px;
  // height: 700px;
  @media screen and (max-width: 960px) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
    padding-top: 50px;
  }
}
.team__monika--text {
  padding-left: 100px;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 2rem;
    border-bottom: solid black 2px;
    margin-bottom: 30px;
    padding-bottom: 5px;
    @media screen and (max-width: 960px) {
      font-size: 1.2rem;
    }
  }
  p {
    text-align: justify;
    font-size: 1.1rem;
    padding-bottom: 10px;
    letter-spacing: 1.5px;
    line-height: 1.7;
    @media screen and (max-width: 960px) {
      font-size: 1rem;
      letter-spacing: 1.2px;
    }
  }
  @media screen and (max-width: 960px) {
    padding: 0;
    padding: 50px 20px;
  }
}

.team__else {
  margin-top: 20px;
}
.team__else--item {
  display: flex;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  width: 100%;
  padding: 0 100px;
  margin-top: 100px;
  padding-bottom: 50px;
  background-color: #234750;
  color: #ffffff;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    padding: 0 10px;
  }
  h1 {
    font-size: 2.5rem;
    @media screen and (max-width: 960px) {
      font-size: 1.5rem;
    }
  }
  h2 {
    font-size: 1rem;
    font-weight: 600;
    padding: 10px;
    margin-top: 20px;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
  }
  &:last-child{
    margin-bottom: 100px;
  }
}
.team__else--text {
  padding-left: 100px;
  letter-spacing: 1.5px;
  line-height: 1.7;
  font-size: 1.1rem;

  p {
    text-align-last: center;
    padding-bottom: 5px;
    text-align: justify;
  }
  @media screen and (max-width: 960px) {
padding: 20px;

  }
}

.team__else--ig {
  object-fit: cover;
  width: 400px;
  margin-bottom: 25px;
  margin-top: 50px;
  @media screen and (max-width: 960px) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    // padding-bottom: 50px;
  }
}

.team__else--name {
  padding-top: 50px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
