.footer{
    background-color: #234750;
    // background-color: #754747;
    color: white;
}
.footer__wrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
}
.footer__contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer__wrapper-links{
    display: flex;
    padding-bottom: 30px;
    @media screen and (max-width: 960px) {
        flex-direction: column;
      }
}
.footer__sm{
    padding-top: 10px;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}
.footer__link{
    text-decoration: none;
    color: white;
    padding: 10px;
}
.icon {
    color: white;
    text-decoration: none;
    font-size: 2rem;
}

.footer__logo{
    display: block;
    width: 150px;
    margin-bottom: 50px;
    margin-top: 50px;
}