.wrapper{
    margin-bottom: 200px;
    color: black;
    text-decoration: none;
    h5 {
        color: black;
    }
    p {
        color: black;
    }
}
.card-title{
    font-size: 1.5rem;
    margin-bottom: 40px;
}

.card-footer{
    font-size: 1.2rem;
}
.history{
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

.type{
    padding-bottom: 15px;
}