.half-wrapper {
  display: flex;
  // height: 700px;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
  }
}

.half-text {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #1e211b;
  h1 {
    font-size: 2rem;
    padding: 20px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    border-bottom: solid 1px rgb(255, 255, 255);
  }

  p {
    font-size: 1rem;
    //   border: 1px solid black;
    line-height: 1.4;
    padding: 20px;
    margin: 0 20px;
    margin-bottom: 20px;
    color: rgb(255, 255, 255);
    // background-color: rgb(243, 235, 224);
  }
  a{
    font-size: 1rem;
    //   border: 1px solid black;
    color: rgb(255, 255, 255);
    border-bottom: 1px solid white;
  }
}

.half-img {
  flex: 1;
  background-color: #1e211b;
  img {
    width: 100%;
    // width: 260px;
    object-fit: cover;
  }
}
