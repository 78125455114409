
.home__img {
  height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  @media only screen and (max-width: 960px) {
    height: auto;
    padding-top: 100px;
  }
}
.mobile_home__img {
width: 100%;
}

.home__scroll-wrapper {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.home__img-name{
  position: absolute;
  left: 40px;
  bottom: 40px;
  p{
    font-size: 2rem ;
  }
}

.home__img-scroll {
  width: 30px;
	height: 60px;
	border: 3px solid #754747;
	border-radius: 60px;
	position: relative;
	&::before {
		content: '';
		width: 12px;
		height: 12px;
		position: absolute;
		top: 10px;
		left: 50%;
		transform: translateX(-50%);
		background-color: #754747;
		border-radius: 50%;
		opacity: 1;
		animation: wheel 2s infinite;
		-webkit-animation: wheel 2s infinite;
	}
}

@keyframes wheel {
	to {
		opacity: 0;
		top: 60px;
	}
}

@-webkit-keyframes wheel {
	to {
		opacity: 0;
		top: 60px;
	}
}


.home__about {
  background-color: rgb(255, 255, 255);
}
.home__about-logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home__about-logo {
  width: 200px;
  padding: 50px;
}

.home__about-text {
  color: #000000;
  display: block;
  margin: 0 200px;
  padding-bottom: 100px;
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
  line-height: 3;
  letter-spacing: 2.5px;
  @media only screen and (max-width: 960px) {
    margin: 0 20px;
    font-weight: 400;
    font-size: 1rem;
  }
}

.link{
  font-size: 3rem;
}