.ProductPage {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  background-color: rgb(243, 243, 243);
}
.ProductPage_top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
  }
}
.ProductPage__imgs {
  width: 70%;
  // height: 800px;
}
.ProductPage__imgs .slide img {
  max-height: 800px;  /* change this to whatever you want */
  width: auto;
  @media only screen and (max-width: 960px) {
    max-height: 200px;  /* change this to whatever you want */
  }
}
.ProductPage_info {
  padding: 20px 100px;
  padding-bottom: 50px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 960px) {
  flex-direction: column;
  padding: 0px 20px;
  }
}
.ProductPage_map{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  gap: 15px;
}
.ProductPage_item {
  padding: 20px;
  @media only screen and (max-width: 960px) {
    padding: 0px;
  }
  strong{
    font-size: 1.1rem;
    @media only screen and (max-width: 960px) {
      font-size: .8rem;
    }
  }
}
.ProductPage_title {
  font-size: 2rem;
  @media only screen and (max-width: 960px) {
    font-size: 1.5rem;
    padding: 15px;
  }
}

.ProductPage_text {
  width: 70%;
  margin: 0 auto;
  text-align: justify;
  line-height: 1.3;
  padding-top: 20px;
  padding-bottom: 0px;
  p{
    padding: 10px 0;
  }
  ul{
    list-style: circle;
    padding-left: 20px;
  }
  h3{
    color: #754747;
    font-size: 1.5rem;
    font-weight: 600;
  }
}

.map-container {
  width: 500px;
  height: 300px;
  @media only screen and (max-width: 960px) {
    width: 80vw;
    height: 150px;
  }
}

.img_test {
  position: relative;

}
.img_test:before{
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0px;
    right: 0px;
    background:  url(watermark.png);
    background-size: 100px auto;
    background-position: 98% 98%;
    background-repeat: no-repeat;
    opacity: 0.8;
  }