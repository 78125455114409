/* Slideshow container */
.opinions {
  width: 100vw;
  min-height: calc(100% - 20px);
  align-items: center;
  position: relative;
  background: #e5e5e5;
  @media only screen and (max-width: 960px) {
    height: auto;
  }
}
.opinions__img {
  width: 100px;
  margin-bottom: 20px;
  display: block;
  margin: 20px auto;
}

/* Slides */
.opinions__wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 6rem;
  min-height: 93vh;
  width: 900px;
  @media only screen and (max-width: 960px) {
    width: auto;
    padding: 0 2rem;
  }
}

.opinions__text {
  text-align: justify;
  font-size: 1.1rem;
  letter-spacing: 1.5px;
  line-height: 1.7;
  padding-bottom: 50px;
  text-align: center;
  @media only screen and (max-width: 960px) {
    font-size: 1rem;
    letter-spacing: 1.2px;
  }
}
.opinions__author {
  text-align: justify;
  font-size: 1.1rem;
  letter-spacing: 1.5px;
  line-height: 1.7;
  @media only screen and (max-width: 960px) {
    font-size: 1rem;
    letter-spacing: 1.2px;
    font-size: 1rem;
  }
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -30px;
  padding: 16px;
  color: #888;
  font-weight: bold;
  font-size: 5vw;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  position: absolute;
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

/* The dot/bullet/indicator container */
.dot-container {
  text-align: center;
  padding: 20px;
  //   background: seagreen;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 8px;
  background-color: #333;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

/* Add a background color to the active dot/circle */
.active {
  background-color: #f3f3d4;
  height: 17px;
  width: 17px;
}
.dot:hover {
  background-color: #ccc;
}

/* Add an italic font style to all quotes */
q {
  font-size: 5.3vw;
  font-style: italic;
  text-align: left;
}

/* Add a blue color to the author */
.author {
  font-size: 5vw;
  color: green;
  text-align: right;
}

@media only screen and (min-width: 1000px) {
  .mySlides {
    padding: 0 10rem;
  }
}
